<template>
  <iframe
    id="iframeTestId"
    ref="iframe"
    allowfullscreen="true"
    :src="getValue"
    frameborder="0"
    :width="getWidth"
    :height="getHeight"
    allow="microphone;camera;midi;encrypted-media;"
  ></iframe>
</template>
<script>
export default {
  name: 'iframes',
  props: {
    data: Object,
    dataChart: Object,
    option: Object,
    component: Object
  },
  computed: {
    getValue() {
      return this.data.value
    },
    getWidth() {
      return this.component.width
    },
    getHeight() {
      return this.component.height
    }
  },
  data() {
    return {
      data1: {
        value: null,
        value2: null,
        timesId: null
      }
    }
  },
  mounted() {
    if (this.$route.query.message) {
      this.postMessageChildren()
    }

    this.getMessageChildren()
  },
  methods: {
    // 向子组件传参
    postMessageChildren() {
      clearTimeout(this.timesId)
      this.timesId = setTimeout(() => {
        let iframeDom = document.getElementById('iframeTestId')
        iframeDom = this.$refs.iframe
        iframeDom.contentWindow.postMessage(
          JSON.parse(decodeURI(this.$route.query.message)),
          '*'
        )
        this.postMessageChildren()
      }, 1000)
    },
    // 接收子组件传过来的消息
    getMessageChildren() {
      // 接收子组件传过来的消息
      window.addEventListener('message', function (msg) {
        window['pointDetail'] = msg.data
        const childData = msg.data || {}
        if (childData && childData.type && childData.type === 'Jump') {
          console.log('Jump')
        } else {
          console.log('no Jump')
        }
        if (msg.data.type === 'emergency') {
          let message = encodeURI(JSON.stringify(msg.data))
          window.location.href = `https://toccbigscreenweb.answer12.com/view/1483774349916557314?message=${message}`
        }
      })
    }
  }
}
</script>
<style scoped>
div a {
  display: inline-block;
  width: 100%;
}
</style>

